import React,{useState, useEffect, useRef} from 'react'; 
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import './OnboardForm.css'
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { ValidationUrl } from "../../Utils/Urls";

export default function OnboardForm() {
    const [LocationId, setLocationId] = useState('');
    const [AccessCode, setAccessCode] = useState('');
    const [PublicKey, setPublicKey] = useState('');
    const toast = useRef(null);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const codeParam = urlParams.get('code');
    
        if (codeParam) {
            setAccessCode(codeParam);
        }
      }, []);

      const handleGetAccessCode = () => {
        window.location.href = 'https://marketplace.gohighlevel.com/oauth/chooselocation?response_type=code&redirect_uri=https://appointment.simplexmarketingsystem.com/&client_id=64947dc743087ad12417dc3b-lj7e26tb&scope=contacts.readonly contacts.write calendars.readonly calendars/events.readonly calendars/events.write locations.readonly opportunities.readonly opportunities.write locations/customFields.readonly locations/customValues.readonly users.readonly locations/tasks.readonly locations/tasks.write locations/tags.readonly locations/tags.write';
      };

      const handleSubmit = (e)=>{
        e.preventDefault();
        const data = {
            LocationId,
            AccessCode,
            PublicKey
        }
        axios.post(ValidationUrl, data, {
            headers: {'Content-Type': 'multipart/form-data' },
        })
        .then((response) => {
            if (response.status === 200) {
                toast.current.show({severity:'success', summary: 'Success', detail:response.data, life: 2000});
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
            }
            })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
        })
      }

    return (
        <div className="card flex flex-column md:flex-row gap-3 onboard-card">
            <Toast ref={toast} />
            <form onSubmit={handleSubmit}>
            <div className="p-inputgroup flex-1 onboard-lable">
                <label className="font-bold block mb-2">
                Simplex Sub-Account Location ID
                </label>
            </div>
            <div className="p-inputgroup flex-1 onboard-text">
                <InputText placeholder="Enter your Simplex Sub-Account Location ID" value={LocationId} onChange={(e)=>setLocationId(e.target.value)} required/>
            </div>

            <div className="p-inputgroup flex-1 onboard-lable">
                <label className="font-bold block mb-2">
                Simplex Private API Token
                </label>
            </div>
            <div className="p-inputgroup flex-1 onboard-text">
                <InputText placeholder="Enter your Simplex Private API Token" value={AccessCode} onChange={(e)=>setAccessCode(e.target.value)} required/>
                <Button icon="pi pi-search" className="p-button-success" onClick={handleGetAccessCode}/>
            </div>

            <div className="p-inputgroup flex-1 onboard-lable">
                <label className="font-bold block mb-2">
                Simplex Sub-Account Location Public API Key
                </label>
            </div>
            <div className="p-inputgroup flex-1 onboard-text">
                <InputText placeholder="Enter your Simplex Sub-Account Location Public API Key" value={PublicKey} onChange={(e)=>setPublicKey(e.target.value)} required/>
            </div>
            <Button type='submit' label="Validate Credentials & Submit"/>
            </form>
        </div>
    )
}