import "primereact/resources/themes/lara-light-indigo/theme.css";    
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import './table.css'
import React, { useState, useEffect,useRef } from 'react';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { MultiSelect } from 'primereact/multiselect';
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from 'primereact/calendar';
import { Dialog } from 'primereact/dialog';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import axios from '../../Utils/axios'
import { allAppointmentsUrl, appointmentOutcomeUrl, allCalendarsUrl, submitCalendar, syncUrl, getTrackingUrl } from "../../Utils/Urls";
import { useParams } from 'react-router-dom';
import { Dropdown } from 'primereact/dropdown';

export default function AppointmentsTable() {
    const {locationId} = useParams()
    const url = `${allAppointmentsUrl}${locationId}`
    const calendarsUrl = `${allCalendarsUrl}${locationId}`
    const trackUrl = `${getTrackingUrl}${locationId}`
    const syncingUrl = `${syncUrl}${locationId}`
    const [customers, setCustomers] = useState(null);
    const [filters, setFilters] = useState(null);
    const [loading, setLoading] = useState(true);
    const [globalFilterValue, setGlobalFilterValue] = useState('');
    const [visible, setVisible] = useState(false);
    const [sync, setSync] = useState(false);
    const [settingsVisible, setSettingsVisible] = useState(false);
    const [infoVisible, setInfoVisible] = useState(false);
    const [outcome, setOutcome] = useState('');
    const [outcomeValue, setOutcomeValue] = useState('');
    const [notes, setNotes] = useState('');
    const [isMounted, setIsMounted] = useState(false)
    const [calendarIsMounted, setCalendarIsMounted] = useState(false)
    const [trackIsMounted, setTrackIsMounted] = useState(false)
    const [id, setId] = useState('')
    const [formId, setFormId] = useState(null)
    const [formName, setFormName] = useState(null)
    const [formFirstName, setFormFirstName] = useState('')
    const [formLastName, setFormLastName] = useState('')
    const [formEmail, setFormEmail] = useState('')
    const [formPhone, setFormPhone] = useState('')
    const iconsAndText = [
        { icon: 'pi pi-check-circle', text: 'Sale', value: 'showed', color:'green'},
        { icon: 'pi pi-times', text: 'No Sale', value: 'showed', color:'yellow' },
        { icon: 'pi pi-hourglass', text: 'Trial', value: 'showed', color:'purple' },
        { icon: 'pi pi-calendar-times', text: 'Did Not Show', value: 'noshow', color:'red' },
        // { icon: 'pi pi-hourglass', text: 'Said they are still thinking', value: 'showed' },
        // { icon: 'pi pi-question-circle', text: 'Something else', value: 'showed' },
        // { icon: 'pi pi-clock', text: 'Started a trial', value: 'showed' },
      ];
    const [calendarsName, setCalendarsName] = useState([])
    const [calendarForm, setCalendarForm] = useState([])
    // const [calendarsName] = useState(['Scheduled Phone Call', 'Book an Appointment To Come In | Free Class and Fitness Assessment ', 'Walk In Appointment Calendar', 'Weigh In Calendar', '1 on 1 Consultation' ]);
    const [allOutcomes] = useState(['confirmed', 'new', 'cancelled', 'showed', 'noshow', 'invalid'])
    const [allProgramSold] = useState(['21 Day Program', '6 Week Challenge', 'Membership', 'Membership w/ Challenge', 'Teacher | Student Summer Promo', '30 Day Pass', '1 Day Trial', '3 Day Trial', 'Drop In', 'Elite Program', 'No Sale'])
    const [allAppointmentStatus] = useState(['No Show', 'Showed | Did Not Buy', 'Sold', 'Cancelled', 'Rescheduled', 'Not Interested/ Not Yet Ready', 'Spam', 'Unable To Contact', 'Contacted'])
    const [allMemberPurchase] = useState(['Yes', 'No'])
    const generateDateRange = () => {
        const today = new Date();
        const lastMonth = new Date();
        lastMonth.setMonth(today.getMonth() - 1);
    
        return [lastMonth, today];
      };
    const [dates, setDates] = useState(() => generateDateRange());
    const [selectedDateType, setSelectedDateType] = useState({ name: 'Appointment Date', code: 'BD' });
    const DateType = [
        { name: 'Appointment Booked On', code: 'BO' },
        { name: 'Appointment Date', code: 'BD' }
    ];
    const toast = useRef(null);
    const footerContent = (
        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => cancelFormSubmit()} className="p-button-text" />
            <Button label="Submit" icon="pi pi-check" onClick={() => submitFormdetails()} autoFocus />
        </div>
    );

    const infoFooterContent = (
        <div>
            <Button label="OK" icon="pi pi-check" onClick={() => setInfoVisible(false)} autoFocus />
        </div>
    );

    const cancelFormSubmit = () => {
        setCalendarIsMounted(!calendarIsMounted)
        setSettingsVisible(false)

    }

    const submitFormdetails = () => {
        console.log(calendarForm)
        axios.post(submitCalendar, calendarForm,{
            headers: {'Content-Type': 'application/json'},
          })
        .then((response)=>{
            if (response.status === 200){
                setCalendarIsMounted(!calendarIsMounted)
                setSettingsVisible(false)
                toast.current.show({severity:'success', summary: 'Success', detail:'Record Submitted', life: 2000});
            }
            else{
                toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
            }
        })
        .catch((error)=>{
            toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
        })
    }

    const handleCloseDialog = () =>{
    setVisible(false)
    setOutcome('')
    setNotes('')
    setOutcomeValue('')
    }

    useEffect(()=>{
        if (dates[1] !== null) {
            setLoading(true);
            const data = {
                selectedDateType,
                dates
            }
            axios.post(url, data, {
                headers: {'Content-Type': 'application/json'},
            })
                .then((response) => {
                    if (response.status === 200) {
                        console.log(response.data)
                        setCustomers(getCustomers(response.data));
                        const firstAppointmentCalendars = response.data.length > 0 ? response.data[0].all_calandar_names : [];
                        setCalendarsName(firstAppointmentCalendars);
                        setLoading(false);
                        // initFilters();
                    }
                    })
                .catch((error)=>{
                    setCustomers(null)
                    
                })
        }
    },[url, isMounted, dates, selectedDateType])

    useEffect(()=>{
        axios.get(calendarsUrl, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setCalendarForm(response.data)
                }
            })
    },[calendarsUrl, calendarIsMounted])

    useEffect(()=>{
        axios.get(trackUrl, {
            headers: {'Content-Type': 'multipart/form-data'},
          })
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    if (response.data.status === 'completed'){
                        setSync(false)
                    }
                    else{
                        setSync(true)
                    }
                }
            })
    },[trackUrl, trackIsMounted])

    useEffect(()=>{
        initFilters();
    },[])

    const getCustomers = (data) => {
        return [...(data || [])].map((d) => {
            d.appointment_booked = new Date(d.appointment_booked);
            d.appointmentbooked_for = new Date(d.appointmentbooked_for);
            return d;
        });
    };

    const formatDate = (value) => {
        return value.toLocaleDateString('en-US', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric'
        })
        // }).replace(/(\d+)\/(\d+)\/(\d+)/, '$2/$1/$3'); // Rearrange the format to DD/MM/YYYY;
      };

    const formatDateTime = (value) => {
        const formattedDate = formatDate(value);
        const formattedTime = value.toLocaleTimeString('en-US', {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit'
        });
        return `${formattedDate}    ${formattedTime}`;
    };

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
            // Calendar_Name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            Calendar_Name: { value: null, matchMode: FilterMatchMode.IN },
            email: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            phone: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            first_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            last_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            full_name: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            program_sold: { value: null, matchMode: FilterMatchMode.IN },
            appointment_status_cf: { value: null, matchMode: FilterMatchMode.IN },
            member_purchase: { value: null, matchMode: FilterMatchMode.IN },
            // Appointment_Status: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] },
            Appointment_Status: { value: null, matchMode: FilterMatchMode.IN },
            appointment_booked: {operator: FilterOperator.AND,constraints: [{ value: null, matchMode: FilterMatchMode.DATE_AFTER }, { value: null, matchMode: FilterMatchMode.DATE_BEFORE }] },
            appointmentbooked_for: {operator: FilterOperator.AND,constraints: [{ value: null, matchMode: FilterMatchMode.DATE_AFTER }, { value: null, matchMode: FilterMatchMode.DATE_BEFORE }] }
        });
        setGlobalFilterValue('');
    };

    const renderHeader = () => {
        return (
            <div className="flex justify-content-between table-header">
                <div className="date-filter-container">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Keyword Search" />
                    </span>
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" outlined onClick={clearFilter} />
                </div>
                <div className="date-filter-container">
                    <Dropdown value={selectedDateType} onChange={(e) => setSelectedDateType(e.value)} options={DateType} optionLabel="name" 
                    placeholder="Select a date feild" className="w-full md:w-14rem" />
                    <span className="p-float-label filter-card-container">
                        <Calendar value={dates} onChange={(e) => setDates(e.value)} selectionMode="range" className="full-width"/>
                        {/* <label htmlFor="ms-cities">Select Date Range</label> */}
                    </span>
                </div>
            </div>
        );
    };

    const dateBookedOnBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome
        return( 
            <div className='status'>
            {formatDateTime(rowData.appointment_booked)}
            </div>
        )
    };

    const dateBookedAtBodyTemplate = (rowData) => {
        const db_outcome = rowData.outcome
        return(
            <div className='status'>
            {formatDateTime(rowData.appointmentbooked_for)}
            </div>
        )
    };

    const dateFilterTemplate = (options) => {
        return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" />;
        // return <Calendar value={options.value} onChange={(e) => options.filterCallback(e.value, options.index)} selectionMode="range" dateFormat="mm/dd/yy" placeholder="mm/dd/yyyy" mask="99/99/9999" readOnlyInput />
    };

    // const handleopendialog = (id, calender, first_name, last_name, email)=>{
    //     if (calender == 'Scheduled Phone Call') {
    //         setFormId('JmZSs3CXEyr6bXXUxSZc');
    //         setFormName('3c. Update Scheduled Call Appointment Status')
    //     } else if (calender == 'Book an Appointment To Come In | Free Class and Fitness Assessment') {
    //         setFormId('EXuKH6Hu2xijtRAJF1nW');
    //         setFormName('3a. Update Appointment Status')
    //     } else if (calender == 'Weigh In Calendar') {
    //         setFormId('QI69kUGYkmFXurS9G2b9');
    //         setFormName('3d. Update Weigh In Appointment Status')
    //     }else if (calender == '1 on 1 Consultation') {
    //         setFormId('xiyTu4O7Qlvnoxl1QOg1');
    //         setFormName('3b. Update Scheduled 1 on 1 Consultation Status')
    //     } else {
    //         setFormId('EXuKH6Hu2xijtRAJF1nW');
    //         setFormName('3a. Update Appointment Status')
    //     }
    //     setId(id)
    //     setFormFirstName(first_name)
    //     setFormLastName(last_name)
    //     setFormEmail(email)
    //     setVisible(true)
    // }

    const handleopendialog = (id, calendar, first_name, last_name, email, phone) => {
        // Define a function to find a calendar entry by name in calendarForm
        const findCalendarEntry = (calendarName) => {
            return calendarForm.find(entry => entry.calendar_name === calendarName);
        }
    
        let form_id, form_name;
    
        // Check if the calendar exists in calendarForm
        const foundCalendar = findCalendarEntry(calendar);
    
        if (foundCalendar) {
            form_id = foundCalendar.form_id;
            form_name = foundCalendar.form_name;
        } else {
            // Default values if the calendar is not found
            form_id = null;
            form_name = null;
        }
    
        setId(id);
        setFormFirstName(first_name);
        setFormLastName(last_name);
        setFormEmail(email);
        setFormPhone(phone);
    
        // Set the form_id and form_name based on the found values
        setFormId(form_id);
        setFormName(form_name);
        if (typeof form_id === 'string' && typeof form_name === 'string' && form_id.length > 0 && form_name.length > 0){
            setVisible(true);
        }
        else{ 
            setInfoVisible(true)
        }
    }

    const handleOutcomeSubmit = () =>{

        if(outcome !== ''){
            const updateUrl = `${appointmentOutcomeUrl}${id}`
            const data = {
                outcome,
                notes,
                outcomeValue
            }
            axios.post(updateUrl, data, {
                headers: {'Content-Type': 'multipart/form-data' },
            })
            .then((response) => {
                if (response.status === 200) {
                    setVisible(false)
                    setOutcome('')
                    setNotes('')
                    setOutcomeValue('')
                    setIsMounted(!isMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Record Submitted', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                }
                })
            .catch((error)=>{
                toast.current.show({severity:'error', summary: 'Error', detail:error.response.data, life: 2000});
            })
        }
        else{
            toast.current.show({severity:'warn', summary: 'Warning', detail:'Please select an outcome', life: 2500});
        }
    }

    const outcomeBodyTemplate = (rowData) => {
        // const db_outcome = rowData.outcome

        return (
        <div className="flex justify-content-center" style={{textAlign:'center'}}>
        <Toast ref={toast} />
        <Button className="set-outcome-button" icon="pi pi-ellipsis-h" onClick={() => handleopendialog(rowData.appointment_id, rowData.Calendar_Name, rowData.first_name, rowData.last_name, rowData.email, rowData.phone)}/>
        
        </div>
        )};

    const redirectBody = (rowData) => {

        return(
            <div className="flex justify-content-center" style={{textAlign:'center'}}>
                <Button className="set-redirect-button" icon="pi pi-external-link" onClick={() => handleRedirect(rowData.contact_id)}/>
            </div>
        )
    }
    

    const handleRedirect = (contactId) => {
        // window.location.href = `https://app.supafitgrow.com/v2/location/${locationId}/contacts/detail/${contactId}`; 
        const url = `https://app.simplexmarketingsystem.com/v2/location/${locationId}/contacts/detail/${contactId}`;
        window.open(url, '_blank');
      };

    const programSoldFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={allProgramSold} itemTemplate={programSoldItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} placeholder="Any" className="p-column-filter" />;
    };

    const programSoldItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const appointmentStatusFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={allAppointmentStatus} itemTemplate={appointmentStatusItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} placeholder="Any" className="p-column-filter" />;
    };

    const memberPurchaseFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={allMemberPurchase} itemTemplate={memberPurchaseItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} placeholder="Any" className="p-column-filter" />;
    };

    const appointmentStatusItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const memberPurchaseItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const outcomeFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={allOutcomes} itemTemplate={outcomeItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} placeholder="Any" className="p-column-filter" />;
    };

    const outcomeItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const programSoldBody = (rowData) => {
        const db_program_sold = rowData.program_sold

        return(
            <div className='status'>
                {db_program_sold? <span>{db_program_sold}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const appointmentStatusBody = (rowData) => {
        const db_appt_status = rowData.appointment_status_cf

        return(
            <div className='status'>
                {db_appt_status? <span>{db_appt_status}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const memberPurchaseBody = (rowData) => {
        const db_member_purchase = rowData.member_purchase

        return(
            <div className='status'>
                {db_member_purchase? <span>{db_member_purchase}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }
    

    const outcomeBody = (rowData) => {
        const db_outcome = rowData.Appointment_Status

        return(
            <div className='status'>
                {db_outcome? <span>{db_outcome}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const fullNameBody = (rowData) => {
        const db_outcome = rowData.outcome
        const fullName = rowData.full_name
        return(
            <div className='status'>
                {fullName? <span>{fullName}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const lastNameBody = (rowData) => {
        const db_outcome = rowData.outcome
        const lastName = rowData.last_name
        return(
            <div className='status'>
                {lastName? <span>{lastName}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const emailBody = (rowData) => {
        const db_outcome = rowData.outcome
        const email = rowData.email
        return(
            <div className='status'>
                {email? <span>{email}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const phoneBody = (rowData) => {
        const db_outcome = rowData.outcome
        const phone = rowData.phone
        return(
            <div className='status'>
                {phone? <span>{phone}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const calendarFilterTemplate = (options) => {
        return <MultiSelect value={options.value} options={calendarsName} itemTemplate={calendarItemTemplate} onChange={(e) => {console.log(e.value); options.filterCallback(e.value)}} placeholder="Any" className="p-column-filter" />;
    };

    const calendarItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option}</span>
            </div>
        );
    };

    const calendarNameBody = (rowData) => {
        const db_outcome = rowData.outcome
        const Calendar_Name = rowData.Calendar_Name
        return(
            <div className='status'>
                {Calendar_Name? <span>{Calendar_Name}</span>:<i className="pi">N/A</i>}
            </div>
        )
    }

    const srNoBodyTemplate = (rowData, props) => {
        const rowIndex = props.rowIndex + 1;
        const db_outcome = rowData.outcome
        return <div className='status'>{rowIndex}</div>;
      };
    
    // Whenever form_id and form_name change, update the corresponding values in calendarForm
    const handleFormChange = (rowData, field, newValue) => {
        const updatedForm = calendarForm.map(item => {
            if (item.calendar_id === rowData.calendar_id) {
                return {
                    ...item,
                    [field]: newValue
                };
            }
            return item;
        });

        setCalendarForm(updatedForm);
    };
    
    const formIdBody = (rowdata) => {
        const formId = rowdata.form_id
        const rowData = rowdata
        return(
            <InputText placeholder="Form ID" id="form_id" value={formId} style={{ width: '90%' }} onChange={(e) => handleFormChange(rowData, 'form_id', e.target.value)}/>
        )
    }

    const formNameBody = (rowdata) => {
        const formName = rowdata.form_name
        const rowData = rowdata
        return(
            <InputText placeholder="Form Name" id="form_name" value={formName} style={{ width: '90%' }} onChange={(e) => handleFormChange(rowData, 'form_name', e.target.value)}/>
        ) 
    }

    const syncHandle = () =>{
        if(sync == false){
            axios.post(syncingUrl,{
                headers: {'Content-Type': 'application/json'},
            })
            .then((response)=>{
                if (response.status === 200){
                    setTrackIsMounted(!trackIsMounted)
                    toast.current.show({severity:'success', summary: 'Success', detail:'Sync has started', life: 2000});
                }
                else{
                    toast.current.show({severity:'error', summary: 'Error', detail:response.data, life: 2000});
                }
            })
        }
        else{
            toast.current.show({severity:'error', summary: 'Error', detail:'It is already syncing', life: 2000});
        }
    }

    const header = renderHeader();

    return (
        <div className="card">
            <div className="settings-container">
                <Button className="p-button-primary" label={sync ? "Synchronizing": "Sync"} icon={sync? "pi icon-size pi-spin pi-sync": "pi icon-size pi-sync"} onClick={syncHandle} />
                <Button className="p-button-danger" label="Settings" icon="pi icon-size pi-cog" onClick={() => setSettingsVisible(true)} />
            </div>
            <DataTable value={customers} scrollable scrollHeight="70vh" paginator showGridlines rows={50} rowsPerPageOptions={[50, 100]} loading={loading} dataKey="appointment_id" 
                    paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    filters={filters} globalFilterFields={['email', 'phone', 'first_name', 'last_name', 'Calendar_Name', 'Appointment_Status', 'appointment_booked', 'appointmentbooked_for', 'full_name', 'program_sold', 'appointment_status_cf', 'member_purchase']} header={header}
                    emptyMessage="No appointments found.">
                <Column header="SR NO" className="padding-0" style={{ width: '2rem'  }} body={srNoBodyTemplate} />
                <Column filterField="full_name" header="Name" field="full_name" sortable filter filterPlaceholder="Search by name" className="padding-0" style={{ width: '7rem' }} body={fullNameBody}/>
                {/* <Column filterField="last_name" header="Last Name" field="last_name" sortable filter filterPlaceholder="Search by last name" className="padding-0" style={{ minWidth: '9rem'  }} body={lastNameBody}/> */}
                {/* <Column filterField="email" header="Email" field="email" sortable filter filterPlaceholder="Search by from" className="padding-0" style={{ width: '7rem'  }} body={emailBody}/> */}
                <Column filterField="phone" header="Phone" field="phone" sortable filter filterPlaceholder="Search by to" className="padding-0" style={{ width: '7rem'  }} body={phoneBody}/>
                <Column header="Appointment Booked On" field="appointment_booked" sortable filterField="appointment_booked" dataType="date" className="padding-0" style={{ width: '12rem' }} body={dateBookedOnBodyTemplate} filterElement={dateFilterTemplate} />
                <Column header="Appointment Date" field="appointmentbooked_for" sortable filterField="appointmentbooked_for" dataType="date" className="padding-0" style={{ width: '12rem' }} body={dateBookedAtBodyTemplate} filterElement={dateFilterTemplate} />
                {/* <Column filterField="Calendar_Name" header="Calandar Name" field="Calendar_Name" sortable filter filterPlaceholder="Search by to" className="padding-0" style={{ width: '12rem'  }} body={calendarNameBody}/> */}
                <Column filterField="Calendar_Name" header="Calandar Name" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} sortable field="Calendar_Name" filter filterElement={calendarFilterTemplate} className="padding-0" style={{ width: '12rem'  }} body={calendarNameBody}/>                    
                {/* <Column header="Outcome" filter filterField="Appointment_Status" field="Appointment_Status" sortable showFilterMatchModes={false} filterMenuStyle={{ width: '6rem' }} className="padding-0" style={{ width: '8rem'  }} body={outcomeBody}/> */}
                <Column header="Program Sold" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }}  filter filterField="program_sold" field="program_sold" filterElement={programSoldFilterTemplate} sortable className="padding-0" style={{ width: '8rem'  }} body={programSoldBody}/>
                <Column header="Appointment Status" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }}  filter filterField="appointment_status_cf" field="appointment_status_cf" filterElement={appointmentStatusFilterTemplate} sortable className="padding-0" style={{ width: '8rem'  }} body={appointmentStatusBody}/>
                <Column header="Member Purchase" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }}  filter filterField="member_purchase" field="member_purchase" filterElement={memberPurchaseFilterTemplate} sortable className="padding-0" style={{ width: '8rem'  }} body={memberPurchaseBody}/>
                <Column header="Outcome" showFilterMatchModes={false} filterMenuStyle={{ width: '14rem' }} filter filterField="Appointment_Status" field="Appointment_Status" filterElement={outcomeFilterTemplate} sortable className="padding-0" style={{ width: '8rem'  }} body={outcomeBody}/>
                <Column field="status" header="Set Outcome" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={outcomeBodyTemplate}/>
                <Column header="Open in CRM" filterMenuStyle={{ width: '14rem' }} style={{ width: '3rem' }} body={redirectBody}/>
            </DataTable>
            <Dialog className="outcome-dialog" visible={visible} onHide={() => handleCloseDialog()}>
            <div className="modal-content" style={{height:'100%'}}>
            <iframe
                src={`https://link.simplexmarketingsystem.com/widget/form/${formId}?first_name=${formFirstName}&last_name=${formLastName}&email=${formEmail}&phone=${formPhone}`}
                style={{height:'100%',  width:'100%', border:'none', borderRadius:'4px'}}
                id={`popup-${formId}`} 
                data-layout="{'id':'POPUP'}"
                data-trigger-type="alwaysShow"
                data-trigger-value=""
                data-activation-type="alwaysActivated"
                data-activation-value=""
                data-deactivation-type="neverDeactivate"
                data-deactivation-value=""
                data-form-name={formName}
                data-height="1268"
                data-layout-iframe-id={`popup-${formId}`}
                data-form-id={formId}
                title={formName}
                    >
                </iframe>
                <script src="https://link.simplexmarketingsystem.com/js/form_embed.js"></script>
            </div>
            </Dialog>

            <ConfirmDialog visible={infoVisible} onHide={() => setInfoVisible(false)} message="Please set the Form Id and Form Name in the settings before setting an outcome." 
                header="Message" icon="pi pi-exclamation-triangle" footer={infoFooterContent}/> 

            <Dialog className="settings-dialog" visible={settingsVisible} onHide={() => cancelFormSubmit()} footer={footerContent}>
            <div className="settings-content" style={{height:'100%'}}>
                <DataTable value={calendarForm} stripedRows tableStyle={{ minWidth: '50rem' }}>
                    <Column className="settings-column" field="calendar_name" header="Calandar" style={{ width: '40%' }}></Column>
                    <Column className="settings-column" field="form_id" header="Form ID" body={formIdBody} style={{ width: '30%' }}></Column>
                    <Column className="settings-column" field="form_name" header="Form Name" body={formNameBody} style={{ width: '30%' }}></Column>
                </DataTable>
            </div>
            </Dialog> 
        </div>
    );
}